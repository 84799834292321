import { useEffect, useState } from 'react';
import Service from '../../@profile/service';
import AuthService, { Bootstrap } from '@hotmart/cas-js';
const config = require('../../../env.config');

const useAuthModule = ({ store, context, CAS_IS_ENABLED }) => {
  const [user, setUser] = useState({});
  const [auth, setAuth] = useState({});
  let bootstrap = {};

  const checkUser = async (token) => {
    const { incrementUser } = store?.actions;
    try {
      const user = await Service.getUser(token);
      const userSecurity = await Service.checkToken(token);
      const fullUserRules = { ...user, ...userSecurity };

      incrementUser(fullUserRules);
      setUser(fullUserRules)
    } catch (e) {
      incrementUser({ name: '', authorities: [] });
      setUser({ name: '', authorities: [] })
    }
  }

  useEffect(() => {
    if (CAS_IS_ENABLED) {
      const userCas = JSON.parse(localStorage.getItem('cas-js:user'));
      const userRedefined = {
        timeZone:
          Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
          'America/SaoPaulo',
        urlPhoto:
          'https://hotmart.com/product/static/images/placeholder/avatar.png'
      };

      if (userCas) {
        setUser({
          ...userCas.profile,
          ...userRedefined
        });

        store?.actions.incrementUser({ ...userRedefined });
        store?.actions.setToken(user.id_token);

        const auth = new AuthService({
          client_id: 'f2d997e6-876e-431e-9c0d-c361b8551763',
          scope: 'openid profile authorities',
          includeIdTokenInSilentRenew: true,
          automaticSilentRenew: true,
          filterProtocolClaims: true,
          loadUserInfo: true,
          env: config.currentEnv === 'production' ? 'production' : 'development'
        });

        bootstrap = new Bootstrap(auth, { signinRedirect: false });
        bootstrap.subscribe({
          next: user => {
            if (user) {
              setUser({ ...user.profile, ...userRedefined });
              store?.actions.incrementUser({
                ...user.profile,
                ...userRedefined
              });
              store?.actions.setToken(user.id_token);
            } else {
              store?.actions.incrementUser({ name: '', authorities: [] });
              setUser({ name: '', authorities: [] });
            }
          }
        });
        bootstrap.start();
      }
    } else {
      const { tokenProfile } = store;

      // if (tokenProfile) {
      //   checkUser(tokenProfile);
      // }
    }
  }, [ CAS_IS_ENABLED ]);

  return {
    user,
    auth
  }
}

export default useAuthModule;
