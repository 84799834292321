import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Utils from '../../../global/scripts/utils';
import { loaderImage } from '../../../utils/image';

const selectPlaceholderLogo = (
  customLogo: string,
  isMarketplace: boolean,
  isMobile: boolean
) => {
  if (customLogo) {
    return (
      <Image src={customLogo} loader={loaderImage} width={160} height={32} />
    );
  }

  if (isMarketplace) {
    if (isMobile) {
      return (
        <svg
          width="132"
          height="24"
          viewBox="0 0 132 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6172 16.3439V7.47919C17.6172 7.44071 17.6554 7.40222 17.6936 7.40222H19.3866C19.4375 7.40222 19.4629 7.44071 19.4629 7.47919V10.6736H19.4884C19.9339 10.1091 20.6086 9.85254 21.3596 9.85254C22.6962 9.85254 23.1163 10.7249 23.1163 11.8923V16.3568C23.1163 16.3953 23.0908 16.4337 23.0399 16.4337H21.3469C21.296 16.4337 21.2705 16.3953 21.2705 16.3568V12.0463C21.2705 11.5459 20.7486 11.392 20.4177 11.392C20.0867 11.392 19.4757 11.6101 19.4757 12.2387V16.3439C19.4757 16.3824 19.4502 16.4209 19.3993 16.4209H17.6936C17.6554 16.4209 17.6172 16.3953 17.6172 16.3439Z"
            fill="#053D4E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.1107 14.5993V11.5332H30.3342C30.2833 11.5332 30.2578 11.4947 30.2578 11.4562V10.3529C30.2578 10.3144 30.2833 10.2888 30.3087 10.2759C30.3342 10.2631 31.3398 9.96805 31.3398 9.96805C31.3398 9.96805 31.8872 8.23615 31.8999 8.2105C31.9126 8.18484 31.9381 8.15918 31.9763 8.15918H32.8928C32.931 8.15918 32.9692 8.18484 32.9692 8.23615V9.96805H34.3312C34.3694 9.96805 34.4076 9.99371 34.4076 10.045V11.4434C34.4076 11.4947 34.3694 11.5203 34.3312 11.5203H32.9692V14.3812C32.9692 14.6762 33.1347 14.8558 33.542 14.8558H34.3312C34.3821 14.8558 34.4076 14.9072 34.4076 14.9328V16.1644C34.4076 16.2029 34.3694 16.2285 34.3567 16.2414C33.873 16.421 33.6056 16.5364 33.0583 16.5364C31.6708 16.5621 31.1107 15.741 31.1107 14.5993Z"
            fill="#053D4E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.5872 14.5993V11.5332H56.8107C56.7598 11.5332 56.7344 11.4947 56.7344 11.4562V10.3529C56.7344 10.3144 56.7598 10.2888 56.7853 10.2759C56.8108 10.2631 57.8164 9.96805 57.8164 9.96805C57.8164 9.96805 58.3637 8.23615 58.3765 8.2105C58.3892 8.18484 58.4146 8.15918 58.4528 8.15918H59.3693C59.4075 8.15918 59.4457 8.18484 59.4457 8.23615V9.96805H60.8078C60.846 9.96805 60.8841 9.99371 60.8841 10.045V11.4434C60.8841 11.4947 60.846 11.5203 60.8078 11.5203H59.4457V14.3812C59.4457 14.6762 59.6112 14.8558 60.0185 14.8558H60.8078C60.8587 14.8558 60.8841 14.9072 60.8841 14.9328V16.1644C60.8841 16.2029 60.846 16.2285 60.8332 16.2414C60.3495 16.421 60.0822 16.5364 59.5348 16.5364C58.1473 16.5621 57.5872 15.741 57.5872 14.5993Z"
            fill="#053D4E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.5273 16.3439V10.0578C35.5273 10.0065 35.5655 9.98083 35.6037 9.98083H37.1312C37.1694 9.98083 37.1949 10.0065 37.2076 10.0321C37.2203 10.0578 37.3858 10.6736 37.3858 10.6736H37.4113C37.8568 10.1091 38.5315 9.85254 39.2698 9.85254C40.1736 9.85254 40.6191 10.2631 40.861 10.8917H40.9119C41.3701 10.1219 42.1721 9.85254 42.9231 9.85254C44.2597 9.85254 44.6798 10.7249 44.6798 11.8923V16.3568C44.6798 16.4081 44.6416 16.4337 44.6034 16.4337H42.8977C42.8467 16.4337 42.8213 16.3953 42.8213 16.3568V12.0848C42.8213 11.5844 42.3503 11.392 42.0193 11.392C41.6374 11.392 41.0646 11.6358 41.0392 12.2002V16.3568C41.0392 16.3953 41.0137 16.4337 40.9628 16.4337H39.2571C39.2189 16.4337 39.1807 16.3953 39.1807 16.3568V12.0848C39.1807 11.5844 38.6842 11.392 38.3151 11.392C37.9841 11.392 37.3858 11.6486 37.3858 12.29V16.3696C37.3858 16.4081 37.3604 16.4466 37.3095 16.4466H35.6164C35.5528 16.4337 35.5273 16.3824 35.5273 16.3439Z"
            fill="#053D4E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.0841 13.6371H48.0658C47.5311 13.6371 47.1747 13.8423 47.1747 14.4196C47.1747 15.0482 47.6202 15.1893 48.053 15.1893C48.4476 15.1893 49.0841 14.9456 49.0841 14.1502V13.6371ZM45.7999 11.392V10.2246C45.7999 10.1861 45.8381 10.1476 45.8636 10.1476C46.7419 9.968 47.5566 9.85254 48.3331 9.85254C49.9752 9.85254 50.9299 10.2502 50.9299 12.0334V16.3568C50.9299 16.4081 50.8917 16.4337 50.8535 16.4337H49.2369C49.1987 16.4337 49.1732 16.4081 49.1605 16.3696C49.1477 16.3311 48.9823 15.7282 48.9823 15.7282H49.0586C48.6004 16.3696 47.9894 16.5492 47.2383 16.5492C46.029 16.5492 45.3926 15.6127 45.3926 14.4966C45.3926 12.816 46.6146 12.4825 47.5184 12.4825H49.0714V12.0591C49.0714 11.5203 48.6768 11.4177 47.2001 11.4177C46.5764 11.4177 45.9018 11.469 45.8636 11.469C45.8381 11.469 45.7999 11.4433 45.7999 11.392Z"
            fill="#053D4E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.0117 16.3439V10.0578C52.0117 10.0193 52.0499 9.98083 52.0881 9.98083H53.6029C53.6411 9.98083 53.6793 10.0065 53.6793 10.045C53.692 10.0835 53.8575 11.02 53.8575 11.02H53.8829C54.2903 10.1733 55.1304 9.86537 55.856 9.85254C55.8942 9.85254 55.9324 9.8782 55.9324 9.92951V11.6871C55.9324 11.7384 55.8942 11.764 55.856 11.764H55.4232C54.2521 11.764 53.8575 12.2387 53.8575 12.816V16.3439C53.8575 16.3953 53.8193 16.4209 53.7811 16.4209H52.0881C52.0499 16.4337 52.0117 16.3953 52.0117 16.3439Z"
            fill="#053D4E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.9048 13.2009C25.9048 14.3042 26.0702 14.9969 26.9486 14.9969C27.8396 14.9969 28.0051 14.317 28.0051 13.2009C28.0051 12.0976 27.8396 11.4177 26.9486 11.4177C26.0702 11.4177 25.9048 12.0976 25.9048 13.2009ZM23.9062 13.2009C23.9062 11.1483 24.6955 9.83972 26.9486 9.83972C29.2144 9.83972 30.0164 11.1483 30.0164 13.2009C30.0164 15.2663 29.2271 16.5621 26.9486 16.5621C24.6955 16.5621 23.9062 15.2663 23.9062 13.2009Z"
            fill="#053D4E"
          />
          <mask
            id="mask0_1325_42515"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="15"
            height="20"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0H14.1423V19.6923H0V0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_1325_42515)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.03969 15.869C5.23212 15.8561 3.76824 14.368 3.7937 12.5335C3.80643 10.7118 5.28304 9.23644 7.10334 9.2621C8.91091 9.27493 10.3748 10.7631 10.3493 12.5976C10.3366 14.4193 8.85999 15.8818 7.03969 15.869ZM14.1427 12.5591C14.2318 10.8016 13.6335 7.92789 12.1696 5.58021C12.1314 5.51607 12.0678 5.54173 12.0805 5.60587C12.1824 5.96508 12.1951 6.60652 11.635 6.56804C10.6294 6.50389 11.6732 4.34864 9.471 2.84766C9.43281 2.82201 9.36917 2.86049 9.39462 2.91181C9.54738 3.20687 9.63648 4.10489 9.29279 4.41279C9.01274 4.65653 8.50357 4.59239 8.00712 3.78417C7.17971 2.43714 7.49795 0.987478 8.05804 0.0894578C8.09623 0.0253134 8.04531 -0.000344305 7.99439 0.0124846C4.9648 1.01314 4.36652 4.60522 3.73005 5.91376C3.62822 6.13185 3.52638 6.24731 3.34817 6.23448C2.80081 6.196 3.19542 5.01574 3.38636 4.60522C3.41182 4.54107 3.34817 4.51542 3.30998 4.5539C1.74427 6.18317 0.331308 8.97986 0.0639911 11.5713C0.0767205 11.5071 0.0385323 11.7894 0.025803 11.9818C0.025803 12.0588 0.0130736 12.1358 0.0130736 12.2256C0.000344218 12.3154 0.000344217 12.418 0.000344217 12.5078C-0.0378439 16.4463 3.10631 19.6535 7.01423 19.692C10.9221 19.7305 14.1045 16.5617 14.1427 12.6233C14.1427 12.5976 14.1427 12.5848 14.1427 12.5591Z"
              fill="#F04E23"
            />
          </g>
          <path
            d="M73.295 7.20984V16.5621H72.3021L72.3148 9.3266L69.298 15.0868H68.5342L65.5046 9.37792L65.5173 16.5621H64.5117V7.20984H65.4028L68.9161 13.9835L72.3912 7.20984H73.295Z"
            fill="#053D4E"
          />
          <path
            d="M79.5965 10.571C79.9911 10.9943 80.1947 11.6358 80.1947 12.4825V16.5492H79.1509V15.446C78.9854 15.8308 78.7309 16.1259 78.3872 16.3312C78.0435 16.5364 77.6489 16.6391 77.2033 16.6391C76.796 16.6391 76.4141 16.5492 76.0704 16.3825C75.7267 16.2157 75.4467 15.9848 75.243 15.6769C75.0393 15.3818 74.9375 15.0483 74.9375 14.6762C74.9375 14.1887 75.0648 13.8167 75.3066 13.5473C75.5485 13.2779 75.9558 13.0855 76.5159 12.97C77.076 12.8545 77.8525 12.7904 78.8581 12.7904H79.1509V12.3542C79.1509 11.8154 79.0364 11.4177 78.82 11.1868C78.6036 10.943 78.2471 10.8276 77.7507 10.8276C77.0124 10.8276 76.2868 11.0585 75.5485 11.5203L75.1921 10.7378C75.5231 10.494 75.9304 10.3016 76.4014 10.1605C76.8724 10.0194 77.3306 9.94238 77.7634 9.94238C78.5908 9.94238 79.2018 10.1605 79.5965 10.571ZM78.6545 15.2664C78.9982 14.9072 79.1637 14.4453 79.1637 13.8808V13.496H78.9218C78.1453 13.496 77.5597 13.5345 77.1524 13.5986C76.7451 13.6628 76.465 13.7782 76.2868 13.9322C76.1213 14.0861 76.0322 14.317 76.0322 14.6121C76.0322 14.9585 76.1595 15.2407 76.4014 15.4716C76.656 15.7025 76.9615 15.8052 77.3433 15.8052C77.878 15.8052 78.3108 15.6256 78.6545 15.2664Z"
            fill="#053D4E"
          />
          <path
            d="M85.5156 10.8789L84.8155 10.943C84.1536 11.0072 83.6826 11.2124 83.3898 11.5716C83.097 11.9308 82.9443 12.367 82.9443 12.8802V16.5621H81.875V10.1091H82.9188V11.2381C83.2752 10.4812 83.9881 10.0707 85.0319 9.98086L85.4138 9.9552L85.5156 10.8789Z"
            fill="#053D4E"
          />
          <path
            d="M90.5936 16.5621L87.3349 13.4703V16.5621H86.2656V7.20984H87.3349V13.0341L90.3263 10.122H91.6756L88.4933 13.2137L91.9811 16.5749H90.5936V16.5621Z"
            fill="#053D4E"
          />
          <path
            d="M97.8377 13.496H93.1532C93.1914 14.2529 93.3951 14.8174 93.7643 15.2022C94.1334 15.5871 94.6553 15.7795 95.33 15.7795C96.081 15.7795 96.7557 15.5229 97.3794 15.0098L97.7358 15.7923C97.4558 16.0618 97.0994 16.267 96.6538 16.421C96.221 16.5749 95.7628 16.6519 95.3172 16.6519C94.3116 16.6519 93.5351 16.3568 92.9496 15.7667C92.3768 15.1766 92.084 14.3555 92.084 13.3164C92.084 12.6493 92.2113 12.072 92.4659 11.5588C92.7205 11.0457 93.0769 10.6608 93.5351 10.3657C93.9934 10.0835 94.5153 9.94238 95.1136 9.94238C95.9664 9.94238 96.6284 10.2246 97.1121 10.7891C97.5958 11.3536 97.8377 12.1233 97.8377 13.0983V13.496ZM93.777 11.3151C93.4333 11.6743 93.2424 12.1746 93.1787 12.816H96.9084C96.8957 12.1618 96.7302 11.6615 96.4247 11.3151C96.1192 10.9687 95.6864 10.7891 95.139 10.7891C94.5662 10.7763 94.108 10.9559 93.777 11.3151Z"
            fill="#053D4E"
          />
          <path
            d="M102.497 15.7539L102.446 16.6006C102.242 16.6391 102.026 16.6519 101.784 16.6519C101.007 16.6519 100.435 16.4466 100.04 16.0361C99.6455 15.6256 99.4545 15.0611 99.4545 14.3427V10.9559H98.207V10.1092H99.4545V8.14636H100.524V10.1092H102.344V10.9559H100.524V14.2657C100.524 15.2792 100.995 15.7924 101.924 15.7924C102.102 15.7924 102.293 15.7795 102.497 15.7539Z"
            fill="#053D4E"
          />
          <path
            d="M108.02 10.3657C108.453 10.648 108.784 11.0457 109.026 11.5588C109.268 12.072 109.382 12.6621 109.382 13.3292C109.382 13.9963 109.268 14.5864 109.026 15.0868C108.784 15.5871 108.453 15.972 108.033 16.2414C107.613 16.5108 107.104 16.6519 106.544 16.6519C106.047 16.6519 105.602 16.5364 105.233 16.3183C104.864 16.1002 104.571 15.7795 104.38 15.3818V18.9482H103.311V10.0963H104.38V11.2124C104.571 10.8019 104.851 10.494 105.233 10.2759C105.602 10.0578 106.047 9.94238 106.544 9.94238C107.091 9.94238 107.588 10.0835 108.02 10.3657ZM107.766 15.1381C108.11 14.7147 108.275 14.1118 108.275 13.3292C108.275 12.5466 108.11 11.9437 107.766 11.5075C107.422 11.0713 106.938 10.8532 106.315 10.8532C105.691 10.8532 105.207 11.0713 104.876 11.4947C104.533 11.918 104.367 12.521 104.367 13.3164C104.367 14.0989 104.533 14.7147 104.876 15.1381C105.22 15.5614 105.691 15.7795 106.315 15.7795C106.938 15.7667 107.422 15.5614 107.766 15.1381Z"
            fill="#053D4E"
          />
          <path
            d="M110.707 7.20984H111.776V16.5621H110.707V7.20984Z"
            fill="#053D4E"
          />
          <path
            d="M117.925 10.571C118.319 10.9943 118.523 11.6358 118.523 12.4825V16.5492H117.479V15.446C117.314 15.8308 117.059 16.1259 116.715 16.3312C116.372 16.5364 115.977 16.6391 115.531 16.6391C115.124 16.6391 114.742 16.5492 114.399 16.3825C114.055 16.2157 113.775 15.9848 113.571 15.6769C113.367 15.3818 113.266 15.0483 113.266 14.6762C113.266 14.1887 113.393 13.8167 113.635 13.5473C113.877 13.2779 114.284 13.0855 114.844 12.97C115.404 12.8545 116.181 12.7904 117.186 12.7904H117.479V12.3542C117.479 11.8154 117.364 11.4177 117.148 11.1868C116.932 10.943 116.575 10.8276 116.079 10.8276C115.341 10.8276 114.615 11.0585 113.877 11.5203L113.52 10.7378C113.851 10.494 114.259 10.3016 114.73 10.1605C115.2 10.0194 115.659 9.94238 116.092 9.94238C116.919 9.94238 117.517 10.1605 117.925 10.571ZM116.97 15.2664C117.314 14.9072 117.479 14.4453 117.479 13.8808V13.496H117.237C116.461 13.496 115.875 13.5345 115.468 13.5986C115.06 13.6628 114.78 13.7782 114.602 13.9322C114.437 14.0861 114.348 14.317 114.348 14.6121C114.348 14.9585 114.475 15.2407 114.717 15.4716C114.971 15.7025 115.277 15.8052 115.659 15.8052C116.206 15.8052 116.639 15.6256 116.97 15.2664Z"
            fill="#053D4E"
          />
          <path
            d="M121.272 16.2414C120.801 15.9719 120.445 15.5743 120.203 15.0868C119.949 14.5864 119.834 13.9963 119.834 13.3292C119.834 12.6621 119.961 12.072 120.216 11.5588C120.47 11.0457 120.84 10.6608 121.311 10.3657C121.782 10.0835 122.329 9.94238 122.965 9.94238C123.398 9.94238 123.818 10.0194 124.226 10.1733C124.633 10.3272 124.977 10.5325 125.231 10.7891L124.875 11.5845C124.264 11.0842 123.653 10.8404 123.029 10.8404C122.367 10.8404 121.858 11.0585 121.489 11.4947C121.12 11.9309 120.941 12.5338 120.941 13.3292C120.941 14.1118 121.12 14.7147 121.489 15.1381C121.858 15.5614 122.367 15.7795 123.029 15.7795C123.653 15.7795 124.264 15.5358 124.875 15.0354L125.231 15.8308C124.964 16.0874 124.62 16.2927 124.2 16.4466C123.793 16.6006 123.36 16.6775 122.927 16.6775C122.278 16.6519 121.731 16.5236 121.272 16.2414Z"
            fill="#053D4E"
          />
          <path
            d="M131.609 13.496H126.925C126.963 14.2529 127.167 14.8174 127.536 15.2022C127.905 15.5871 128.427 15.7795 129.101 15.7795C129.853 15.7795 130.527 15.5229 131.151 15.0098L131.507 15.7923C131.227 16.0618 130.871 16.267 130.425 16.421C129.993 16.5749 129.534 16.6519 129.089 16.6519C128.083 16.6519 127.307 16.3568 126.721 15.7667C126.148 15.1766 125.855 14.3555 125.855 13.3164C125.855 12.6493 125.983 12.072 126.237 11.5588C126.492 11.0457 126.848 10.6608 127.307 10.3657C127.765 10.0835 128.287 9.94238 128.885 9.94238C129.738 9.94238 130.4 10.2246 130.884 10.7891C131.367 11.3536 131.609 12.1233 131.609 13.0983V13.496ZM127.548 11.3151C127.205 11.6743 127.014 12.1746 126.95 12.816H130.68C130.667 12.1618 130.502 11.6615 130.196 11.3151C129.891 10.9687 129.458 10.7891 128.911 10.7891C128.338 10.7763 127.892 10.9559 127.548 11.3151Z"
            fill="#053D4E"
          />
        </svg>
      );
    }
    return (
      <svg
        width="182"
        height="34"
        viewBox="0 0 182 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.291 22.973V10.7839C24.291 10.731 24.3437 10.6781 24.3963 10.6781H26.7306C26.8008 10.6781 26.8359 10.731 26.8359 10.7839V15.1762H26.871C27.4853 14.4001 28.4155 14.0473 29.451 14.0473C31.2939 14.0473 31.8731 15.2468 31.8731 16.852V22.9906C31.8731 23.0435 31.838 23.0964 31.7678 23.0964H29.4335C29.3633 23.0964 29.3282 23.0435 29.3282 22.9906V17.0637C29.3282 16.3757 28.6086 16.164 28.1523 16.164C27.6959 16.164 26.8535 16.4639 26.8535 17.3283V22.973C26.8535 23.0259 26.8184 23.0788 26.7482 23.0788H24.3963C24.3437 23.0788 24.291 23.0435 24.291 22.973Z"
          fill="#053D4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.8947 20.574V16.3581H41.8241C41.7539 16.3581 41.7188 16.3052 41.7188 16.2523V14.7353C41.7188 14.6823 41.7539 14.6471 41.789 14.6294C41.8241 14.6118 43.2106 14.2061 43.2106 14.2061C43.2106 14.2061 43.9653 11.8247 43.9829 11.7894C44.0004 11.7542 44.0355 11.7189 44.0882 11.7189H45.3518C45.4045 11.7189 45.4571 11.7542 45.4571 11.8247V14.2061H47.3351C47.3878 14.2061 47.4404 14.2413 47.4404 14.3119V16.2346C47.4404 16.3052 47.3878 16.3405 47.3351 16.3405H45.4571V20.2741C45.4571 20.6798 45.6853 20.9268 46.2469 20.9268H47.3351C47.4053 20.9268 47.4404 20.9974 47.4404 21.0326V22.726C47.4404 22.779 47.3878 22.8142 47.3702 22.8319C46.7033 23.0788 46.3347 23.2376 45.58 23.2376C43.6669 23.2729 42.8947 22.1439 42.8947 20.574Z"
          fill="#053D4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.4005 20.574V16.3581H78.3299C78.2597 16.3581 78.2246 16.3052 78.2246 16.2523V14.7353C78.2246 14.6823 78.2597 14.6471 78.2948 14.6294C78.3299 14.6118 79.7164 14.2061 79.7164 14.2061C79.7164 14.2061 80.4711 11.8247 80.4887 11.7894C80.5062 11.7542 80.5413 11.7189 80.594 11.7189H81.8577C81.9103 11.7189 81.963 11.7542 81.963 11.8247V14.2061H83.8409C83.8936 14.2061 83.9463 14.2413 83.9463 14.3119V16.2346C83.9463 16.3052 83.8936 16.3405 83.8409 16.3405H81.963V20.2741C81.963 20.6798 82.1911 20.9268 82.7528 20.9268H83.8409C83.9112 20.9268 83.9463 20.9974 83.9463 21.0326V22.726C83.9463 22.779 83.8936 22.8142 83.876 22.8319C83.2091 23.0788 82.8405 23.2376 82.0858 23.2376C80.1728 23.2729 79.4005 22.1439 79.4005 20.574Z"
          fill="#053D4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.9844 22.9729V14.3295C48.9844 14.2589 49.037 14.2236 49.0897 14.2236H51.1958C51.2485 14.2236 51.2836 14.2589 51.3011 14.2942C51.3187 14.3295 51.5468 15.1762 51.5468 15.1762H51.5819C52.1962 14.4 53.1264 14.0472 54.1444 14.0472C55.3905 14.0472 56.0048 14.6117 56.3383 15.4761H56.4085C57.0403 14.4177 58.1461 14.0472 59.1816 14.0472C61.0244 14.0472 61.6036 15.2467 61.6036 16.852V22.9906C61.6036 23.0611 61.551 23.0964 61.4983 23.0964H59.1465C59.0763 23.0964 59.0412 23.0435 59.0412 22.9906V17.1165C59.0412 16.4286 58.3918 16.164 57.9354 16.164C57.4089 16.164 56.6191 16.4992 56.584 17.2753V22.9906C56.584 23.0435 56.5489 23.0964 56.4787 23.0964H54.1268C54.0742 23.0964 54.0215 23.0435 54.0215 22.9906V17.1165C54.0215 16.4286 53.3371 16.164 52.8281 16.164C52.3717 16.164 51.5468 16.5168 51.5468 17.3988V23.0082C51.5468 23.0611 51.5117 23.114 51.4415 23.114H49.1072C49.0195 23.0964 48.9844 23.0258 48.9844 22.9729Z"
          fill="#053D4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.6777 19.251H66.2736C65.5365 19.251 65.045 19.5332 65.045 20.327C65.045 21.1913 65.6593 21.3854 66.2561 21.3854C66.8002 21.3854 67.6777 21.0502 67.6777 19.9565V19.251ZM63.1495 16.164V14.5588C63.1495 14.5059 63.2022 14.453 63.2373 14.453C64.4483 14.206 65.5716 14.0472 66.6422 14.0472C68.9063 14.0472 70.2226 14.5941 70.2226 17.046V22.9906C70.2226 23.0611 70.17 23.0964 70.1173 23.0964H67.8883C67.8357 23.0964 67.8006 23.0611 67.783 23.0082C67.7655 22.9553 67.5373 22.1262 67.5373 22.1262H67.6426C67.0108 23.0082 66.1683 23.2552 65.1328 23.2552C63.4654 23.2552 62.5879 21.9675 62.5879 20.4328C62.5879 18.122 64.2728 17.6634 65.5189 17.6634H67.6602V17.0813C67.6602 16.3404 67.1161 16.1993 65.0801 16.1993C64.2201 16.1993 63.2899 16.2698 63.2373 16.2698C63.2022 16.2698 63.1495 16.2346 63.1495 16.164Z"
          fill="#053D4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.7129 22.9729V14.3295C71.7129 14.2766 71.7655 14.2236 71.8182 14.2236H73.9068C73.9594 14.2236 74.0121 14.2589 74.0121 14.3118C74.0296 14.3648 74.2578 15.6525 74.2578 15.6525H74.2929C74.8545 14.4882 76.0129 14.0649 77.0133 14.0472C77.066 14.0472 77.1186 14.0825 77.1186 14.1531V16.5697C77.1186 16.6403 77.066 16.6756 77.0133 16.6756H76.4166C74.8019 16.6756 74.2578 17.3282 74.2578 18.122V22.9729C74.2578 23.0435 74.2052 23.0788 74.1525 23.0788H71.8182C71.7655 23.0964 71.7129 23.0435 71.7129 22.9729Z"
          fill="#053D4E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.7165 18.6513C35.7165 20.1683 35.9446 21.1208 37.1557 21.1208C38.3842 21.1208 38.6124 20.1859 38.6124 18.6513C38.6124 17.1342 38.3842 16.1993 37.1557 16.1993C35.9446 16.1993 35.7165 17.1342 35.7165 18.6513ZM32.9609 18.6513C32.9609 15.8289 34.0491 14.0297 37.1557 14.0297C40.2798 14.0297 41.3855 15.8289 41.3855 18.6513C41.3855 21.4913 40.2973 23.2729 37.1557 23.2729C34.0491 23.2729 32.9609 21.4913 32.9609 18.6513Z"
          fill="#053D4E"
        />
        <mask
          id="mask0_1325_41093"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="28"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0.5H19.4993V27.5769H0V0.5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1325_41093)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.70624 22.3199C7.21398 22.3022 5.1956 20.256 5.23071 17.7336C5.24826 15.2287 7.28418 13.2002 9.79399 13.2354C12.2863 13.2531 14.3046 15.2993 14.2695 17.8218C14.252 20.3266 12.216 22.3375 9.70624 22.3199ZM19.4998 17.7688C19.6226 15.3522 18.7977 11.4009 16.7793 8.17285C16.7267 8.08465 16.6389 8.11993 16.6565 8.20813C16.7969 8.70204 16.8144 9.58403 16.0422 9.53111C14.6557 9.44291 16.0948 6.47944 13.0585 4.4156C13.0058 4.38032 12.9181 4.43324 12.9532 4.5038C13.1638 4.90951 13.2867 6.14429 12.8128 6.56764C12.4267 6.90279 11.7246 6.8146 11.0401 5.7033C9.8993 3.85113 10.3381 1.85784 11.1103 0.623065C11.163 0.534867 11.0928 0.499588 11.0226 0.517227C6.84541 1.89312 6.02051 6.83224 5.14295 8.63148C5.00254 8.93136 4.86213 9.09012 4.61642 9.07248C3.86172 9.01956 4.4058 7.39671 4.66907 6.83224C4.70417 6.74404 4.61642 6.70876 4.56376 6.76168C2.40498 9.00192 0.456803 12.8474 0.0882302 16.4106C0.105781 16.3224 0.0531279 16.7105 0.0355768 16.9751C0.0355768 17.0809 0.0180257 17.1867 0.0180257 17.3102C0.000474603 17.4337 0.000474603 17.5748 0.000474603 17.6983C-0.0521787 23.1137 4.28294 27.5236 9.67114 27.5765C15.0593 27.6294 19.4471 23.2724 19.4998 17.857C19.4998 17.8218 19.4998 17.8041 19.4998 17.7688Z"
            fill="#F04E23"
          />
        </g>
        <path
          d="M101.059 10.4136V23.2729H99.6905L99.708 13.3241L95.5484 21.2443H94.4954L90.3182 13.3947L90.3358 23.2729H88.9492V10.4136H90.1778L95.0219 19.7273L99.8133 10.4136H101.059Z"
          fill="#053D4E"
        />
        <path
          d="M109.748 15.0352C110.292 15.6174 110.573 16.4993 110.573 17.6636V23.2553H109.134V21.7383C108.905 22.2675 108.554 22.6732 108.081 22.9555C107.607 23.2377 107.063 23.3788 106.448 23.3788C105.887 23.3788 105.36 23.2553 104.886 23.026C104.412 22.7967 104.026 22.4792 103.745 22.0558C103.465 21.6501 103.324 21.1915 103.324 20.6799C103.324 20.0096 103.5 19.4981 103.833 19.1277C104.167 18.7572 104.728 18.4926 105.501 18.3339C106.273 18.1751 107.343 18.0869 108.73 18.0869H109.134V17.4872C109.134 16.7463 108.976 16.1995 108.677 15.8819C108.379 15.5468 107.888 15.388 107.203 15.388C106.185 15.388 105.185 15.7056 104.167 16.3406L103.675 15.2646C104.132 14.9294 104.693 14.6648 105.343 14.4708C105.992 14.2767 106.624 14.1709 107.221 14.1709C108.361 14.1709 109.204 14.4708 109.748 15.0352ZM108.449 21.4914C108.923 20.9975 109.151 20.3624 109.151 19.5863V19.0571H108.818C107.747 19.0571 106.94 19.11 106.378 19.1982C105.816 19.2864 105.43 19.4452 105.185 19.6568C104.956 19.8685 104.834 20.186 104.834 20.5917C104.834 21.068 105.009 21.4561 105.343 21.7736C105.694 22.0911 106.115 22.2322 106.641 22.2322C107.379 22.2322 107.975 21.9853 108.449 21.4914Z"
          fill="#053D4E"
        />
        <path
          d="M117.908 15.4585L116.943 15.5467C116.03 15.6349 115.381 15.9172 114.977 16.4111C114.574 16.905 114.363 17.5047 114.363 18.2103V23.2729H112.889V14.4002H114.328V15.9524C114.819 14.9117 115.802 14.3472 117.241 14.2238L117.768 14.1885L117.908 15.4585Z"
          fill="#053D4E"
        />
        <path
          d="M124.911 23.2729L120.418 19.0217V23.2729H118.943V10.4136H120.418V18.422L124.542 14.4178H126.403L122.015 18.669L126.824 23.2906H124.911V23.2729Z"
          fill="#053D4E"
        />
        <path
          d="M134.898 19.0571H128.439C128.492 20.0978 128.773 20.874 129.282 21.4032C129.791 21.9324 130.51 22.197 131.44 22.197C132.476 22.197 133.406 21.8442 134.266 21.1386L134.758 22.2146C134.371 22.585 133.88 22.8673 133.266 23.0789C132.669 23.2906 132.037 23.3965 131.423 23.3965C130.036 23.3965 128.966 22.9907 128.158 22.1793C127.369 21.3679 126.965 20.239 126.965 18.8101C126.965 17.8929 127.14 17.0991 127.491 16.3935C127.842 15.6879 128.334 15.1587 128.966 14.753C129.598 14.3649 130.317 14.1709 131.142 14.1709C132.318 14.1709 133.231 14.559 133.898 15.3351C134.564 16.1113 134.898 17.1696 134.898 18.5103V19.0571ZM129.299 16.0583C128.825 16.5523 128.562 17.2402 128.474 18.1222H133.617C133.599 17.2226 133.371 16.5346 132.95 16.0583C132.529 15.5821 131.932 15.3351 131.177 15.3351C130.387 15.3175 129.755 15.5644 129.299 16.0583Z"
          fill="#053D4E"
        />
        <path
          d="M141.321 22.1616L141.251 23.3258C140.97 23.3788 140.672 23.3964 140.338 23.3964C139.267 23.3964 138.478 23.1142 137.934 22.5497C137.39 21.9852 137.126 21.2091 137.126 20.2213V15.5644H135.406V14.4002H137.126V11.7013H138.601V14.4002H141.11V15.5644H138.601V20.1154C138.601 21.509 139.25 22.2145 140.531 22.2145C140.777 22.2145 141.04 22.1969 141.321 22.1616Z"
          fill="#053D4E"
        />
        <path
          d="M148.939 14.753C149.536 15.1411 149.992 15.6879 150.326 16.3935C150.659 17.0991 150.817 17.9105 150.817 18.8278C150.817 19.745 150.659 20.5565 150.326 21.2444C149.992 21.9324 149.536 22.4616 148.957 22.832C148.378 23.2024 147.676 23.3965 146.903 23.3965C146.219 23.3965 145.605 23.2377 145.096 22.9378C144.587 22.638 144.183 22.197 143.92 21.6501V26.554H142.445V14.3826H143.92V15.9172C144.183 15.3528 144.569 14.9294 145.096 14.6295C145.605 14.3297 146.219 14.1709 146.903 14.1709C147.658 14.1709 148.343 14.3649 148.939 14.753ZM148.588 21.315C149.062 20.7329 149.29 19.9038 149.29 18.8278C149.29 17.7518 149.062 16.9227 148.588 16.3229C148.114 15.7232 147.447 15.4233 146.587 15.4233C145.727 15.4233 145.06 15.7232 144.604 16.3053C144.13 16.8874 143.902 17.7165 143.902 18.8101C143.902 19.8862 144.13 20.7329 144.604 21.315C145.078 21.8971 145.727 22.197 146.587 22.197C147.447 22.1793 148.114 21.8971 148.588 21.315Z"
          fill="#053D4E"
        />
        <path
          d="M152.643 10.4136H154.117V23.2729H152.643V10.4136Z"
          fill="#053D4E"
        />
        <path
          d="M162.594 15.0352C163.138 15.6174 163.419 16.4993 163.419 17.6636V23.2553H161.979V21.7383C161.751 22.2675 161.4 22.6732 160.926 22.9555C160.452 23.2377 159.908 23.3788 159.294 23.3788C158.732 23.3788 158.206 23.2553 157.732 23.026C157.258 22.7967 156.872 22.4792 156.591 22.0558C156.31 21.6501 156.17 21.1915 156.17 20.6799C156.17 20.0096 156.345 19.4981 156.679 19.1277C157.012 18.7572 157.574 18.4926 158.346 18.3339C159.119 18.1751 160.189 18.0869 161.576 18.0869H161.979V17.4872C161.979 16.7463 161.821 16.1995 161.523 15.8819C161.225 15.5468 160.733 15.388 160.049 15.388C159.031 15.388 158.03 15.7056 157.012 16.3406L156.521 15.2646C156.977 14.9294 157.539 14.6648 158.188 14.4708C158.838 14.2767 159.47 14.1709 160.066 14.1709C161.207 14.1709 162.032 14.4708 162.594 15.0352ZM161.277 21.4914C161.751 20.9975 161.979 20.3624 161.979 19.5863V19.0571H161.646C160.575 19.0571 159.768 19.11 159.206 19.1982C158.645 19.2864 158.259 19.4452 158.013 19.6568C157.785 19.8685 157.662 20.186 157.662 20.5917C157.662 21.068 157.837 21.4561 158.171 21.7736C158.522 22.0911 158.943 22.2322 159.47 22.2322C160.224 22.2322 160.821 21.9853 161.277 21.4914Z"
          fill="#053D4E"
        />
        <path
          d="M167.21 22.832C166.56 22.4616 166.069 21.9147 165.736 21.2444C165.385 20.5565 165.227 19.745 165.227 18.8278C165.227 17.9105 165.402 17.0991 165.753 16.3935C166.104 15.6879 166.613 15.1587 167.262 14.753C167.912 14.3649 168.667 14.1709 169.544 14.1709C170.141 14.1709 170.72 14.2767 171.282 14.4884C171.843 14.7001 172.317 14.9823 172.668 15.3351L172.177 16.4288C171.334 15.7408 170.492 15.4057 169.632 15.4057C168.719 15.4057 168.017 15.7056 167.508 16.3053C166.999 16.9051 166.753 17.7341 166.753 18.8278C166.753 19.9038 166.999 20.7329 167.508 21.315C168.017 21.8971 168.719 22.197 169.632 22.197C170.492 22.197 171.334 21.8618 172.177 21.1739L172.668 22.2675C172.3 22.6203 171.826 22.9025 171.247 23.1142C170.685 23.3259 170.088 23.4317 169.491 23.4317C168.596 23.3965 167.842 23.2201 167.21 22.832Z"
          fill="#053D4E"
        />
        <path
          d="M181.461 19.0571H175.002C175.054 20.0978 175.335 20.874 175.844 21.4032C176.353 21.9324 177.073 22.197 178.003 22.197C179.038 22.197 179.969 21.8442 180.829 21.1386L181.32 22.2146C180.934 22.585 180.443 22.8673 179.828 23.0789C179.231 23.2906 178.6 23.3965 177.985 23.3965C176.599 23.3965 175.528 22.9907 174.721 22.1793C173.931 21.3679 173.527 20.239 173.527 18.8101C173.527 17.8929 173.703 17.0991 174.054 16.3935C174.405 15.6879 174.896 15.1587 175.528 14.753C176.16 14.3649 176.88 14.1709 177.705 14.1709C178.88 14.1709 179.793 14.559 180.46 15.3351C181.127 16.1113 181.461 17.1696 181.461 18.5103V19.0571V19.0571ZM175.862 16.0583C175.388 16.5523 175.125 17.2402 175.037 18.1222H180.179C180.162 17.2226 179.934 16.5346 179.512 16.0583C179.091 15.5821 178.494 15.3351 177.74 15.3351C176.95 15.3175 176.336 15.5644 175.862 16.0583Z"
          fill="#053D4E"
        />
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.802 123.427">
      <path
        d="M111.302 102.425v-55.6a.47.47 0 0 1 .5-.5h10.7c.3 0 .5.3.5.5v20.1h.2c2.8-3.6 7.1-5.2 11.8-5.2 8.4 0 11.1 5.5 11.1 12.8v28c0 .2-.2.5-.5.5h-10.7a.47.47 0 0 1-.5-.5v-27c0-3.2-3.3-4.1-5.4-4.1s-6 1.4-6 5.3v25.7c0 .2-.2.5-.5.5h-10.7c-.2 0-.5-.2-.5-.5zm85.3-11v-19.1h-4.9a.47.47 0 0 1-.5-.5v-6.9c0-.2.2-.4.3-.5.2-.1 6.5-1.9 6.5-1.9l3.5-11c.1-.2.2-.3.5-.3h5.8a.47.47 0 0 1 .5.5v10.8h8.6c.2 0 .5.2.5.5v8.8c0 .3-.3.5-.5.5h-8.6v18c0 1.9 1.1 3 3.6 3h5c.3 0 .5.3.5.5v7.7c0 .3-.2.4-.3.5-3 1.1-4.7 1.8-8.2 1.8-8.7-.1-12.3-5.2-12.3-12.4zm167.4 0v-19.1h-4.9a.47.47 0 0 1-.5-.5v-6.9c0-.2.2-.4.3-.5.2-.1 6.5-1.9 6.5-1.9l3.5-11c.1-.2.2-.3.5-.3h5.8a.47.47 0 0 1 .5.5v10.8h8.6c.2 0 .5.2.5.5v8.8c0 .3-.3.5-.5.5h-8.6v18c0 1.9 1.1 3 3.6 3h5c.3 0 .5.3.5.5v7.7c0 .3-.2.4-.3.5-3 1.1-4.7 1.8-8.2 1.8-8.8-.1-12.3-5.2-12.3-12.4zm-139.5 11v-39.5a.47.47 0 0 1 .5-.5h9.7c.2 0 .4.2.5.4 0 .2 1.1 4 1.1 4h.2c2.8-3.6 7-5.2 11.7-5.2 5.7 0 8.5 2.6 10.1 6.6h.3c2.9-4.9 8-6.6 12.7-6.6 8.4 0 11.1 5.5 11.1 12.8v28a.47.47 0 0 1-.5.5h-10.7c-.3 0-.5-.3-.5-.5v-26.8c0-3.2-3-4.4-5.1-4.4-2.4 0-6.1 1.5-6.2 5.1v26c0 .2-.2.5-.5.5h-10.8a.47.47 0 0 1-.5-.5v-26.8c0-3.2-3.2-4.4-5.5-4.4-2.1 0-5.8 1.6-5.8 5.6v25.6a.47.47 0 0 1-.5.5h-10.7c-.4.1-.6-.2-.6-.4zm64.9-31v-7.3c0-.3.2-.5.4-.5 5.6-1.1 10.7-1.9 15.6-1.9 10.4 0 16.4 2.5 16.4 13.7v27.1a.47.47 0 0 1-.5.5h-10.2c-.3 0-.4-.2-.5-.4s-1.1-4-1.1-4h.5c-2.9 4-6.8 5.2-11.5 5.2-7.6 0-11.7-5.9-11.7-12.9 0-10.5 7.7-12.6 13.4-12.6h9.9v-2.7c0-3.3-2.5-4-11.9-4-4 0-8.2.3-8.4.3-.1 0-.4-.3-.4-.5zm20.8 14h-6.4c-3.4 0-5.6 1.3-5.6 4.9 0 3.9 2.8 4.8 5.5 4.8 2.5 0 6.5-1.5 6.5-6.5zm18.5 17v-39.5a.47.47 0 0 1 .5-.5h9.6c.3 0 .5.2.5.4s1.1 6.1 1.1 6.1h.2c2.6-5.3 7.9-7.2 12.5-7.4a.47.47 0 0 1 .5.5v11c0 .3-.3.5-.5.5h-2.7c-7.4 0-9.9 2.9-9.9 6.6v22.2c0 .3-.3.5-.5.5h-10.7c-.3.1-.6-.1-.6-.4zm-177.6-19.7c0-12.9 5-21 19.3-21s19.4 8.2 19.4 21c0 12.9-5 21-19.4 21-14.3 0-19.3-8.1-19.3-21zm12.6 0c0 7 1 11.2 6.6 11.2 5.7 0 6.7-4.3 6.7-11.2 0-7-1-11.2-6.7-11.2-5.5 0-6.6 4.2-6.6 11.2z"
        fill="#053d4e"
      />
      <path
        d="M89.402 78.725c.6-11-3.2-29-12.4-43.8-.2-.4-.7-.2-.6.2.6 2.2.8 6.3-2.8 6.1-6.3-.4.2-14-13.7-23.3-.3-.2-.6.1-.5.4 1 1.8 1.5 7.5-.7 9.4-1.8 1.5-5 1.1-8.1-4-5.2-8.5-3.2-17.5.3-23.2.3-.4-.1-.6-.4-.5-19.1 6.3-22.9 28.8-27 37-.7 1.4-1.3 2.1-2.4 2-3.5-.2-1-7.7.2-10.2.2-.4-.2-.6-.5-.3-9.8 10.2-18.7 27.7-20.4 43.9.1-.4-.2 1.3-.3 2.6h0c0 .5-.1 1-.1 1.5v1.8c-.2 24.7 19.6 44.8 44.3 45.1 24.7.2 44.8-19.6 45.1-44.3v-.4zm-44.9 20.7c-11.4-.1-20.6-9.4-20.5-20.9s9.4-20.6 20.9-20.5 20.6 9.4 20.5 20.9-9.4 20.6-20.9 20.5z"
        fill="#f04e23"
      />
    </svg>
  );
};

export const Placeholder = ({
  setLoadHeader,
  isMobile,
  isMarketplace,
  t,
  customLogo,
  language
}) => {
  const router = useRouter();
  const testParam = router?.query.v;

  return (
    <div
      className="hm-mktplace-header__container"
      onPointerEnter={(e) => {
        e.preventDefault();
        setLoadHeader(true);
      }}
      onTouchMove={(e) => {
        e.preventDefault();
        setLoadHeader(true);
      }}
      onWheel={(e) => {
        e.preventDefault();
        setLoadHeader(true);
      }}
      onMouseEnter={(e) => {
        e.preventDefault();
        setLoadHeader(true);
      }}
    >
      <div
        className="hm-mktplace-header__container--center hm-mktplace-header--placeholder"
        role="region"
      >
        <button className="hm-burger-button" aria-label="Menu">
          <svg
            id="hm-mktplace-menu__mobile"
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 4.75c0-.375.328-.75.75-.75h19.5c.375 0 .75.375.75.75 0 .422-.375.75-.75.75H2.25a.74.74 0 0 1-.75-.75Zm0 7.5c0-.375.328-.75.75-.75h19.5c.375 0 .75.375.75.75 0 .422-.375.75-.75.75H2.25a.74.74 0 0 1-.75-.75Zm20.25 8.25H2.25a.74.74 0 0 1-.75-.75c0-.375.328-.75.75-.75h19.5c.375 0 .75.375.75.75 0 .422-.375.75-.75.75Z"
              fill="#191C1F"
            ></path>
          </svg>
        </button>
        <div
          className={classNames(
            'hm-mktplace-header__logo hm-mktplace-header__logo--loading hm-mktplace-header__logo--placeholder',
            { 'hm-mktplace-header__logo--marketplace': isMarketplace }
          )}
        >
          <a
            href={
              isMarketplace
                ? Utils.mountMarketplacePage(language)
                : Utils.mountHotmartHomePage(language)
            }
            className="hm-mktplace-header__logo__link"
            aria-label={t('error.page_button_home')}
            data-gtm-event="True"
            data-event-category="Product:Header:New"
            data-event-action="Click:Logo:Home"
            data-event-label="Home"
          >
            {selectPlaceholderLogo(customLogo, isMarketplace, isMobile)}
          </a>
        </div>
        {isMarketplace && (
          <>
            <div className="hm-mktplace-header__categories-container">
              <button
                id="hm-mktplace-header__categories"
                className="hm-mktplace-header__user hm-mktplace-header__categories hm-mktplace-header__title"
              >
                {t('general.categories')}
                <svg
                  width="16"
                  height="16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 1.412C0 .632.672 0 1.5 0S3 .632 3 1.412c0 .78-.672 1.412-1.5 1.412S0 2.19 0 1.412Zm1.5.47c-.276 0-.5-.21-.5-.47s.224-.47.5-.47.5.21.5.47-.224.47-.5.47ZM0 8c0-.78.672-1.412 1.5-1.412S3 7.22 3 8c0 .78-.672 1.412-1.5 1.412S0 8.78 0 8Zm1.5.47c-.276 0-.5-.21-.5-.47s.224-.47.5-.47.5.21.5.47-.224.47-.5.47ZM1.5 13.177c-.828 0-1.5.632-1.5 1.411C0 15.368.672 16 1.5 16S3 15.368 3 14.588c0-.78-.672-1.411-1.5-1.411ZM1 14.588c0 .26.224.47.5.47s.5-.21.5-.47-.224-.47-.5-.47-.5.21-.5.47Z"
                    fill="#191C1F"
                  ></path>
                  <path
                    d="M5.5.941c-.276 0-.5.21-.5.47s.224.471.5.471h10c.276 0 .5-.21.5-.47s-.224-.47-.5-.47h-10ZM5 8c0-.26.224-.47.5-.47h10c.276 0 .5.21.5.47s-.224.47-.5.47h-10c-.276 0-.5-.21-.5-.47ZM5.5 14.118c-.276 0-.5.21-.5.47s.224.47.5.47h10c.276 0 .5-.21.5-.47s-.224-.47-.5-.47h-10Z"
                    fill="#191C1F"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="hm-mktplace-header__search">
              <div className="hm-mktplace-search__container hm-mktplace-search__container--underneath">
                <div className="hm-mktplace-search-bar">
                  <form method="get" action="#">
                    <div className="hm-mktplace-search__autocomplete hm-mktplace-search__autocomplete--show-shadow">
                      <div className="hm-mktplace-search__autocomplete__input">
                        <input
                          type="text"
                          name="q"
                          className="hm-mktplace-search__autocomplete__input__field"
                          onChange={() => {}}
                          placeholder={t('search.what_would_you_like_to_learn')}
                          maxLength={50}
                          autoComplete="off"
                          value=""
                        />
                      </div>
                      <button
                        type="submit"
                        className="hm-mktplace-search__autocomplete__searchSubmit"
                        aria-label={t('search.button')}
                      >
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.292 16a8 8 0 1 1 .707-.707l5.855 5.853a.5.5 0 0 1-.708.708l-5.854-5.855ZM17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                            fill="#191C1F"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
        {!isMarketplace && (
          <div className="hm-mktplace-header__search">
            <div className="hm-mktplace-search__container hm-mktplace-search__container--underneath"></div>
          </div>
        )}
        <div className="hm-mktplace-header__action hm-mktplace-header__action--logged-out">
          <div className="hm-mktplace-menu__header__avatar--mobile hm-mktplace-menu__placeholder">
            <div className="hm-mktplace-header__action">
              <span className="hm-search-close-button hm-search-close-button--closed">
                <svg
                  className="hm-search-close-button__icon-search"
                  width="24"
                  height="24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.292 16a8 8 0 1 1 .707-.707l5.855 5.853a.5.5 0 0 1-.708.708l-5.854-5.855ZM17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                    fill="#191C1F"
                  ></path>
                </svg>
              </span>
            </div>
          </div>
          {testParam && (
            <a
              href="#"
              className="hm-mktplace-header__organic"
              data-gtm-event="True"
              data-event-category="Product:Header:New"
              data-event-action="Click:OrganicSignup"
              data-event-label="Signup"
            >
              <span className="hm-mktplace-header__organic--mobile">
                Ensine Aqui
              </span>
              <span className="hm-mktplace-header__organic--desktop">
                Ensine na Hotmart
              </span>
            </a>
          )}
          <a
            href="#"
            className="hm-mktplace-header__session__signin hm-mktplace-header__title"
            data-gtm-event="True"
            data-event-category="Product:Header:New"
            data-event-action="Click:SignIn"
            data-event-label="SignIn"
          >
            <span>{t('general.login')}</span>
          </a>
          <a
            href="#"
            target="_new"
            className="hm-mktplace-header__session__signup hm-mktplace-header__title hm-mktplace-header__session__signup--active"
            data-gtm-event="True"
            data-event-category="Product:Header:New"
            data-event-action="Click:Register"
            data-event-label="Register"
          >
            <span>{t('general.register')}</span>
          </a>
        </div>
      </div>
    </div>
  );
};
