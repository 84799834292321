import Service from '../@profile/service';
import { useContext, useEffect, useState } from 'react';
import { ProfileContext } from '../components/core/Provider/ProfileProvider';

const useFeatureSwitch = (props) => {
  const context = useContext(ProfileContext);
  const { featureSwitch = {} } = context;
  const [ features, setFeatures ] = useState();

  async function getFeatureSwitch() {
    return Service.getFeatureSwitch();
  }

  useEffect(() => {
    if (!featureSwitch.hasOwnProperty('CAS_IS_ENABLED')) {
      getFeatureSwitch().then(value => {
        setFeatures(value);
        context.actions.setFeatureSwitch(value);
      });
    }

  }, [])


  if (features) {
    return {
      ...features
    };
  }

  if (featureSwitch) {
    return {
      ...featureSwitch
    };
  }
}

export default useFeatureSwitch;
