import React, { useCallback, useRef } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';
import { CollapsibleProps } from './types';
const AccordionTab = dynamic(() => import('components/Collapsible/Accordion'), { ssr: false });
import sanitizeHtml from 'sanitize-html';

const Collapsible = (props: CollapsibleProps) => {
  const lastItemFaq = useRef(null);
  const minHeightFaqItems = 64;
  const totalItemsFaq = props?.items?.length || 0;

  const mountCollapsible = () => {
    return props?.items?.map((item, index) => {
      if (item.title && item.content) {
        return (
          <AccordionTab
            key={item?.uuid}
            index={index}
            title={item.title}
            lastItem={item?.lastItem ? lastItemFaq : null}
            className={props?.className}
            uuid={item?.uuid}
            environment={props?.environment}
            productId={props?.productId}
          >
            <p
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(item.content) }}
              className="accordion__description"
            />
          </AccordionTab>
        );
      }
    });
  };

  const memoCollapsible = useCallback(mountCollapsible, [props?.items]);

  return (
    <div className={`${props.type.toString().toLowerCase()}`}>
      <div
        className={classnames('accordion', props?.className)}
        style={{
          minHeight: totalItemsFaq
            ? `${totalItemsFaq * minHeightFaqItems}px`
            : 'auto'
        }}
      >
        {memoCollapsible()}
      </div>
    </div>
  );
};

export default React.memo(Collapsible);
